import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import logo from "../../images/itboomi_logo.svg";
import logoDark from "../../images/itboomi_logo_dark.svg";

import { Link, useLocation } from "react-router-dom";
import PermPhoneMsgTwoToneIcon from "@mui/icons-material/PermPhoneMsgTwoTone";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { OurServiceList } from "../utilities/utility";
import theme from "../theme/Theme"; // Adjust the path based on your project structure
import RoundedButtonWithIcon from "../UiComponents/RoundedButton";

import btnArrow from "../../images/stararrow.svg";

function Header() {
  const location = useLocation();

  const transparentPaths = ["/", "/blogs", "/contact"]; // Add paths where header should be transparent

  const isActive = (path) => {
    return location.pathname === path;
  };

  const shouldHeaderBeTransparent = transparentPaths.includes(
    location.pathname
  );

  const [scrollPos, setScrollPos] = useState(0);
  const [showHeader, setShowHeader] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setShowHeader(currentScrollPos < scrollPos || currentScrollPos < 10);
    setScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPos]);

  const [burgerState, setBurgerState] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const handleButtonClick = () => {
    const phoneNumber = "+919080447727";
    const pretext =
      "Hi there! I'm interested in knowing more about what you offer and how it could benefit my business. Can you share some details and perhaps give me some advice based on my needs?";
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      pretext
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <Container className={showHeader ? "show" : "hide"}>
      <a href="/">
        <LogoImage src={logoDark} alt="Mini-Dreams-Logo" />
      </a>

      <Menu>
        <StyledLink to="/" className={isActive("/") ? "active" : ""}>
          Home
        </StyledLink>
        <StyledLink to="/about" className={isActive("/about") ? "active" : ""}>
          About
        </StyledLink>
        <StyledLink
          to="/portfolio"
          className={isActive("/portfolio") ? "active" : ""}
        >
          Portfolio
        </StyledLink>
        {/* <Link to="/products" className={isActive('/products') ? 'active' : ''}>
          Products
        </Link> */}
        <StyledLink
          to="/services"
          className={isActive("/services") ? "active" : ""}
        >
          Services
        </StyledLink>

        {/* <Link to="/blogs" className={isActive('/blogs') ? 'active' : ''}>
          Blogs
        </Link> */}
        <StyledLink to="/blogs" className={isActive("/blogs") ? "active" : ""}>
          Blogs
        </StyledLink>
        <StyledLink
          to="/contact"
          className={isActive("/contact") ? "active" : ""}
        >
          Contact us
        </StyledLink>
      </Menu>

      <ContactMenu>
        <Link to="https://web.whatsapp.com/" target="_blank">
          <RoundedButton onClick={handleButtonClick}>
            Let's Talk
            <Icon src={btnArrow} alt="Button Icon" className="rotate" />
          </RoundedButton>
        </Link>
      </ContactMenu>

      <RightMenu className={showHeader ? "show" : "hide"}>
        <CustomMenu onClick={() => setBurgerState(true)}></CustomMenu>
      </RightMenu>

      <BurgerNavMenu show={burgerState}>
        <CloseWrapper>
          <LogoImageDark src={logo} alt="Mini-Dreams-Logo" />

          <CloseBtn onClick={() => setBurgerState(false)} />
        </CloseWrapper>
        <StyledLink
          to="/"
          isActive={isActive("/")}
          onClick={() => setBurgerState(false)}
        >
          Home
        </StyledLink>
        <StyledLink
          to="/about"
          isActive={isActive("/about")}
          onClick={() => setBurgerState(false)}
        >
          About
        </StyledLink>
        <StyledLink
          to="/portfolio"
          isActive={isActive("/portfolio")}
          onClick={() => setBurgerState(false)}
        >
          Portfolio
        </StyledLink>
        <StyledLink
          to="/services"
          isActive={isActive("/services")}
          onClick={() => setBurgerState(false)}
        >
          Services
        </StyledLink>

        {/* {expanded && (
          <ChildContainer>
            {OurServiceList().map((item, index) => (
              <div key={index}>
                <Link to={`${item.link}`} onClick={() => setBurgerState(false)}>
                  <MenuItem>
                    <Image src={item["img"]} />
                    <SubTitle>{item["title"]}</SubTitle>
                  </MenuItem>
                </Link>
              </div>
            ))}
          </ChildContainer>
        )} */}
        <StyledLink
          to="/blogs"
          isActive={isActive("/contact")}
          onClick={() => setBurgerState(false)}
        >
          Blogs
        </StyledLink>
        <StyledLink
          to="/contact"
          isActive={isActive("/contact")}
          onClick={() => setBurgerState(false)}
        >
          Contact Us
        </StyledLink>
      </BurgerNavMenu>
    </Container>
  );
}

export default Header;

const URL = process.env.WhatsAppUrl;
console.log(URL);
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RoundedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-right: 1rem;
  max-width: 20.625rem;
  height: 3rem;
  font-weight: 500;
  font-family: "Trap-Bold", sans-serif;
  border-radius: 30px;
  background: ${theme.colors.textPrimary};
  color: ${theme.colors.white};
  font-size: 0.75rem;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease-in-out; /* Added transition effect on color change */

  &:hover {
    color: ${theme.colors.hoverColor}; /* Change text color on hover */
    .rotate {
      animation: ${rotate360} 2s infinite linear; /* Rotate the arrow continuously on hover */
    }
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-top: 2rem;
    height: 2.5rem;
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    margin-top: 2rem;
    height: 2.5rem;
    padding: 1.5rem;
  }
`;

const Icon = styled.img`
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  @media (max-width: 768px) {
    width: 2rem;
    height: 2rem;
  }

  @media (max-width: 480px) {
    width: 1.625rem;
    height: 1.625rem;
  }
`;

const Container = styled.div`
  display: flex;
  min-height: 80px;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  width: 100%;
  margin-top: 3rem;
  top: 0;
  position: fixed;
  left: 0;
  right: 0;
  background-color: ${(props) => (props.transparent ? "transparent" : "white")};
  transition: background-color 0.3s ease-in-out;

  @media (min-width: 768px) {
    &.show {
      transform: translateY(0);
      padding: 0 0px;
      transition: transform 0.3s ease-in-out;
    }

    &.hide {
      transform: translateY(-100%);
      transition: transform 0.3s ease-in-out;
    }
  }
  @media (max-width: 550px) {
    &.show {
      padding: 0 0px;
      transition: transform 0.3s ease-in-out;
    }

    &.hide {
      padding: 0 0px;
      display: none;
      transition: transform 0.3s ease-in-out;
    }
  }
  @media (max-width: 480px) {
  }
`;

const LogoImage = styled.img`
  width: 120px;
  height: auto;
  text-align: start;
  padding-left: 2rem;
  @media (max-width: 550px) {
    width: 60%;
    padding-left: 2rem;
  }
`;
const LogoImageDark = styled.img`
  width: 60px;
  height: auto;
  text-align: start;
  padding-left: 2rem;
  @media (max-width: 550px) {
    width: 30%;
    padding-left: 0rem;
  }
`;

const ContactMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 50px;
  padding-left: 2rem;

  @media (max-width: 768px) {
    display: none;
  }

  a {
    font-weight: 600;
    padding: 0 5px;
    align-items: center;
    text-align: center;
    color: #5a5b75;
    justify-content: center;
    flex-wrap: nowrap;

    &.active {
      color: #0d0d0d;
      font-weight: 600;
    }
  }
`;

const ContactIcon = styled(PermPhoneMsgTwoToneIcon)`
  cursor: pointer;
`;

const CustomMenu = styled(MenuIcon)`
  cursor: pointer;
  color: #0d0d0d;
`;

const RightMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2rem;
  @media (min-width: 768px) {
    display: none;
  }
  a {
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 10px;
    flexwrap: nowrap;
  }
`;

const BurgerNavMenu = styled.div`
  position: fixed;
  margin-top: 3rem;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  padding: 30px;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  text-align: start;
  transform: ${(props) => (props.show ? `translateX(0)` : `translateX(100%)`)};
  transition: transform 0.2s ease-in;
  li {
    padding: 15px;
    border-bottom: 1px solid #000;

    a {
      font-weight: bold;
    }
  }
`;
const CloseBtn = styled(CloseIcon)`
  display: flex;
  cursor: pointer;
  left: 0;
  color: white;
`;

const CloseWrapper = styled.div`
  display: flex;
  flexdirection: "column";
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const ServicesSection = styled.div`
  position: relative;
  &:hover .popup {
    display: block;
  }
`;

const PopupMenu = styled.div`
  position: absolute;
  display: none;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 550px) {
    padding: 1rem;
  }
`;

const Image = styled.img`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
`;

const SubTitle = styled.h2`
  font-weight: 500;
  font-size: 0.85rem;
  color: grey;
  white-space: nowrap;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

const StyledLink = styled(Link)`
  all: unset;
  font-weight: 700;
  flex-wrap: nowrap;
  color: #fff;
  cursor: pointer;

  padding: 1rem;
  &.active {
    color: #fff;
  }
`;

const expandAnimation = keyframes`
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
`;

const ChildContainer = styled.div`
  /* styles for the child container */
  transform-origin: top;
  ${({ expanded }) =>
    expanded
      ? css`
          animation: ${expandAnimation} 0.3s ease-in forwards;
        `
      : css`
          animation: ${expandAnimation} 0.3s ease-out forwards;
        `}
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  font-family: "Trap-Bold", sans-serif;
  a {
    font-weight: 600;
    font-size: 0.9375rem;
    padding: 0 16px;
    flex-wrap: nowrap;
    color: #5a5b75;
    text-decoration: none;
    border-radius: 100px;
    transition: background-color 0.3s ease-in-out;
    &.active {
      color: ${theme.colors.primaryPink};
      padding: 0.75rem 1rem; // Adjust padding for mobile
      @media (max-width: 768px) {
        a {
          font-size: 11px;
          padding: 0 6px;
        }
      }
    }

    &:hover {
      color: ${theme.colors.primaryPink};
    }
  }

  @media (max-width: 768px) {
    display: none;
    a {
      font-size: 11px;
      padding: 0 6px;
    }
  }
`;
