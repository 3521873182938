import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import theme from "../theme/Theme";
import RoundedButtonWithIcon from "../UiComponents/RoundedButton";
import Logo from "../../images/itboomi_logo.svg";
import Linkedin from "../../images/footer/itboomi_linkedin.svg";
import Instagram from "../../images/footer/itboomi_insta.svg";
import Twitter from "../../images/footer/itboomi_x.svg";
import Email from "../../images/footer/email.svg";
import Call from "../../images/footer/call.svg";
import Location from "../../images/footer/location.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../db/firebase";

const Footer = () => {
  const [emailId, setEmailId] = useState("");
  const handleEmailClick = () => {
    window.location.href = "mailto:hi@itboomi.com";
  };

  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const [hiddenTreasureText, setHiddenTreasureText] = useState("");

  const openWhatsApp = () => {
    const message = `Hello!, I found the hidden treasure : ${hiddenTreasureText}! and I would love to learn more about the services offered by MiniDreams. Can you please provide me with more information?`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919080447727&text=${encodedMessage}`;
    window.open(whatsappUrl);
  };

  const handleHiddenTreasureClick = (text) => {
    setHiddenTreasureText(text);
    openWhatsApp();
  };

  const handleButtonClick = () => {
    const phoneNumber = "+919080447727";
    const pretext =
      "Hi there! I'm interested in knowing more about what you offer and how it could benefit my business. Can you share some details and perhaps give me some advice based on my needs?";
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      pretext
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  const handleClick = (url) => {
    // console.log(url);
    // if (url) {
    //   // Open the URL in a new tab if it's provided
    //   window.open(url, "_blank");
    // }
  };

  const handleData = async () => {
    if (!emailId) {
      toast.error("Enter the email before click the subscribe button");
    } else {
      try {
        const docRef = await addDoc(collection(db, "SubscribedEmailId"), {
          id: emailId,
        });
        setEmailId("");
      } catch (error) {
        console.error("Error adding Email", error);
        toast.error("Error, Please try again later");
      }
    }
  };
  return (
    <FooterContainer>
      <ParentGrid>
        <LeftGrid>
          <NewsHeaderText>
            Curious what's next?
            <br></br>
            <span>
              Join our newsletter for exclusive content, deals,<br></br>
              and industry insights delivered straight to your inbox.
            </span>
          </NewsHeaderText>
        </LeftGrid>
        <ParentGrid>
          <RightGrid>
            <FormField>
              <Input
                type="email"
                name="email"
                required
                placeholder="Enter Your email"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
              />
            </FormField>
          </RightGrid>
          <RightGrid onClick={handleData}>
            {" "}
            <RoundedButtonWithIcon
              text=" Subscribe"
              type="submit"
            ></RoundedButtonWithIcon>
          </RightGrid>
        </ParentGrid>
      </ParentGrid>

      <GradientLine />

      <ParentGrid>
        <LeftGrid>
          <LogoWrapper>
            <a href="/">
              <LogoImage
                src={Logo}
                alt="itboomi - Mobile App and Website Development Company Logo, Coimbatore"
              />
            </a>
          </LogoWrapper>
          <HeaderText>
            Think. Design. Develop.<br></br> Launch. Scale.
          </HeaderText>
          <Title>Follow</Title>
          <SocialLinks>
            <SocialItem>
              <a
                href="https://www.instagram.com/itboomiindia/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon src={Instagram} alt="itboomi Instagram" />
              </a>
              <SocialText
                onClick={handleClick("https://www.instagram.com/itboomiindia/")}
              ></SocialText>
            </SocialItem>
            <SocialItem>
              <a
                href="https://twitter.com/itboomiindia"
                target="_blank"
                rel="noreferrer"
              >
                <Icon src={Twitter} alt="itboomi Twitter" />
              </a>
              <SocialText
                onClick={handleClick("https://twitter.com/itboomiindia")}
              ></SocialText>
            </SocialItem>
            <SocialItem>
              <a
                href="https://www.linkedin.com/company/itboomi/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon src={Linkedin} alt="itboomi LinkedIn" />
              </a>
              <SocialText
                onClick={handleClick(
                  "https://www.linkedin.com/company/itboomi/"
                )}
              ></SocialText>
            </SocialItem>
          </SocialLinks>
        </LeftGrid>
        <ParentGrid>
          <RightGrid>
            <NavTitle>Company</NavTitle>
            <PageLinks>
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about">About</NavLink>
              <NavLink to="/portfolio">Portfolio</NavLink>
              <NavLink to="/services">Services</NavLink>
              <NavLink to="/contact">Contact Us</NavLink>
            </PageLinks>
          </RightGrid>
        </ParentGrid>
      </ParentGrid>

      <GradientLine />

      <FooterContent>
        <p>&copy; 2024 itboomi. All Rights Reserved.</p>
      </FooterContent>
    </FooterContainer>
  );
};

// Styled Components

const ParentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: top;
  max-width: 100vw;
  @media (max-width: 768px) {
    display: block;
  }

  @media (max-width: 480px) {
    display: block;
    padding-top: 2rem;
  }
`;
const Icon = styled.img`
  width: 30px;
  height: 30px;
`;
const FooterContainer = styled.div`
  padding: 4rem;
  background-color: black;
  text-align: left;
  padding-bottom: 6rem;

  @media (max-width: 600px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;
const FormField = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const InputGlow = keyframes`
  0% {
    box-shadow: 0 0 5px ${theme.colors.primaryPink};
  }
  50% {
    box-shadow: 0 0 20px ${theme.colors.primaryPink};
  }
  100% {
    box-shadow: 0 0 5px ${theme.colors.primaryPink};
  }
`;

const Input = styled.input`
  padding: 20px;
  font-size: 14px;
  border: 1px;
  /* border-radius: 2rem; */
  border-radius: 1rem;
  width: 100%;
  height: 3.5rem;
  @media (max-width: 768px) {
    height: 1.875rem;
  }

  @media (max-width: 480px) {
    height: 1.875rem;
  }

  &:focus {
    border-color: ${theme.colors.primaryPink};
    outline: none;
    animation: ${InputGlow} 1.5s ease-in-out infinite;
  }
  `;

const LeftGrid = styled.div`
  gap: 2rem;
  flex-direction: row;
`;

const RightGrid = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  max-width: 100vw;
`;

const LogoWrapper = styled.div`
  padding: 0.25rem;
  @media (max-width: 768px) {
    display: block;
    padding: 0rem;
  }

  @media (max-width: 480px) {
    display: block;
    padding: 0rem;
  }
`;

const LogoImage = styled.img`
  width: 112.5px;
  height: auto;
  padding-bottom: 2rem;
`;

const HeaderText = styled.h2`
  color: ${theme.colors.white};
  font-family: "Trap-Black", sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.5;
  margin-top: 1.5rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
  span {
    color: ${theme.colors.textSecondary};
  }

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const NewsHeaderText = styled.h2`
  color: ${theme.colors.white};
  font-family: "Trap-Black", sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.5;
  margin-top: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
  span {
    font-size: 1rem;
    font-family: "Trap-Medium", sans-serif;

    color: ${theme.colors.textSecondaryWhite};
  }

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const GradientLine = styled.div`
  height: 1px;
  background: linear-gradient(45deg, #fc4985, #fc4985);
  margin-top: 3rem;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    margin-bottom: 0rem;
  }

  @media (max-width: 480px) {
    margin-bottom: 0rem;
  }
`;

const NavTitle = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-family: "Trap-Bold", sans-serif;
  font-size: 1.125rem;
  cursor: pointer;
  color: white;
  color: transparent;
  background: linear-gradient(45deg, #fc4985, #fc4985);
  -webkit-background-clip: text;
  background-clip: text;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    padding-top: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
    padding-top: 1rem;
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-family: "Trap-Bold", sans-serif;
  font-size: 1.125rem;
  margin-top: 2rem;
  cursor: pointer;
  color: white;
  color: transparent;
  background: linear-gradient(45deg, #fc4985, #fc4985);
  -webkit-background-clip: text;
  background-clip: text;
  @media (max-width: 768px) {
    padding-top: 1rem;
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    padding-top: 1rem;
    font-size: 1.2rem;
  }
`;

const NavLink = styled(Link)`
  display: block;
  margin-top: 0.2rem;
  color: ${theme.colors.textSecondaryDark};
  text-decoration: none;
  font-family: "Trap-Regular", sans-serif;
  font-size: 0.9375rem;

  &:hover {
    color: transparent;
    background: linear-gradient(45deg, #fc4985, #fc4985);
    -webkit-background-clip: text;
    background-clip: text;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: left;
  gap: 1rem;
  justify-content: left;
  align-items: left;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media (max-width: 480px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const PageLinks = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media (max-width: 480px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const SocialItem = styled.div`
  align-items: center;
  justify-content: center;
  textalign: center;
`;

const SocialText = styled.p`
  color: ${theme.colors.textSecondaryDark};
  font-family: "Trap-Regular", sans-serif;
  font-size: 0.9375rem;
  margin-left: 0.5rem;
  &:hover {
    cursor: pointer;
    color: transparent;
    background: linear-gradient(45deg, #fc4985, #fc4985);
    -webkit-background-clip: text;
    background-clip: text;
  }
`;

const FooterContent = styled.div`
  color: ${theme.colors.white};
  margin-top: 3rem;

  p {
    &:hover {
      color: transparent;
      background: linear-gradient(45deg, #fc4985, #fc4985);
      -webkit-background-clip: text;
      background-clip: text;
    }
  }
`;

export default Footer;
