import React from "react";
import { Triangle } from "react-loader-spinner";
import styled from "styled-components";

const ParentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: black;
`;

const Title = styled.h2`
  color: white;
  font-family: "Trap-Black", sans-serif;
`;

const PageLoader = () => {
  return (
    <ParentDiv>
      <Triangle
        visible={true}
        height="80"
        width="80"
        color="rgb(189, 65, 196)"
        ariaLabel="triangle-loading"
      />
      <Title>itboomi-Loading</Title>
    </ParentDiv>
  );
};

export default PageLoader;
