/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */
export function OurServiceList(name, profession) {
  return [
    {
      id: 1,
      title: "Design",
      link: "/graphics-designing-agency-coimbatore",
      backgroundColor: "#020205",
      works: [
        "Cross-Platform",
        "Intuitive UI/UX",
        "Performance",
        "Scalability",
        "Store Optimisation",
      ],
      alt: "Transform your business with our custom mobile app development service in Coimbatore. Get started today!",

      img: require("../../images/home/design.svg").default,
      desc: `At itboomi innovations , we go beyond software development to create captivating experiences. Our UI and UX designers craft stunning, intuitive interfaces that engage your customers. Transform your vision into exceptional design with us. `,
    },
    {
      id: 2,
      title: "App Development",
      works: [
        "Creativity",
        "Brand Design",
        "Illustration",
        "Responsive Design",
      ],
      link: "/app-development-agency-coimbatore",
      alt: "Capture attention with our striking graphics design service in Coimbatore. Contact us for a free consultation!",
      backgroundColor: "#020205",
      desc: `itboomi innovations brings your vision to life with custom mobile apps. As a top mobile app development company in Coimbatore, our expert developers craft powerful solutions tailored to your needs. Stand out from the competition with Itboomi.`,
      img: require("../../images/home/appdev.svg").default,
    },
    {
      id: 1,
      title: "Web Development",
      backgroundColor: "#020205",
      color: "#AACFFF",
      textColor: "#fff",
      link: "web-development-agency-coimbatore",
      works: [
        "SEO Mastery",
        "Mobile-Friendly",
        "User-Friendly Interface",
        "Scalability for Future Growth",
      ],
      alt: "Revolutionize your online presence with our bespoke web development service in Coimbatore. Contact us now!",
      img: require("../../images/home/webdev.svg").default,
      desc: `Your website is your storefront, and itboomi innovations is a leading web development company in Coimbatore. Our web experts create user-friendly interfaces and stunning visuals that engage visitors and deliver results. Trust us to build a website that works as hard as you do.`,
    },

    {
      id: 4,
      works: ["SEO", "SEM", "SMM", "PPC"],
      alt: "Boost your online visibility with our results-driven digital marketing service in Coimbatore. Request a strategy session now!",
      title: "Digital Marketing",
      link: "/digital-marketing-agency-coimbatore",
      backgroundColor: "#020205",
      desc: `Are you a business owner looking to scale? itboomi innovations, a leading digital marketing company in Coimbatore, has got your back! We leverage data to craft customized strategies that drive engagement and unlock new revenue streams. `,
      img: require("../../images/home/scale.svg").default,
    },

    // {
    //   id: 5,
    //   title: "Training",
    //   img: require('../image/home/training-minidreams.webp').default,
    //   desc: (
    //     <span>
    //    `&quot;`Revolutionize Your Business with Expert Analysis, Innovative Solutions, and Complex Problem-Solving`&quot;`
    //     </span>
    //   ),
    // },
  ];
}

export function OurServiceList1() {
  return [
    {
      id: 2,
      title: "Mobile App \n\n Development",
      link: "/app-developmen-agency-coimbatore",
      backgroundColor: "#020205",
      works: [
        "Cross-Platform",
        "Intuitive UI/UX",
        "Performance",
        "Scalability",
        "Store Optimisation",
      ],
      alt: "Transform your business with our custom mobile app development service in Coimbatore. Get started today!",

      img: require("../../images/home/app-development-itboomi.svg").default,
      desc: "Unlock the power of mobile app development to drive growth and engagement for your business. Get started today with our expert team.",
    },
    {
      id: 1,
      title: "Web\n\nDevelopment",
      backgroundColor: "#020205",
      color: "#AACFFF",
      textColor: "#fff",
      link: "web-development-agency-coimbatore",
      works: [
        "SEO Mastery",
        "Mobile-Friendly",
        "User-Friendly Interface",
        "Scalability for Future Growth",
      ],
      alt: "Revolutionize your online presence with our bespoke web development service in Coimbatore. Contact us now!",
      img: require("../../images/home/web-development-itboomi.svg").default,
      desc: "Unlock the secrets of web development and take your online presence to the next level. Dive into expert tips and strategies today!",
    },
    {
      id: 3,
      works: [
        "Creativity",
        "Brand Design",
        "Illustration",
        "Responsive Design",
      ],
      link: "/graphics-designing-agency-coimbatore",
      alt: "Capture attention with our striking graphics design service in Coimbatore. Contact us for a free consultation!",
      title: "Graphic \n\n Design",
      backgroundColor: "#020205",
      desc: "Elevate your brand with stunning graphics design solutions. From logos to branding materials, unlock the potential of visual storytelling. ",
      img: require("../../images/home/design-itboomi.svg").default,
    },
    {
      id: 4,
      works: ["SEO", "SEM", "SMM", "PPC"],
      alt: "Boost your online visibility with our results-driven digital marketing service in Coimbatore. Request a strategy session now!",
      title: "Digital \n\n Marketing",
      link: "/digital-marketing-agency-coimbatore",
      backgroundColor: "#020205",
      desc: "Supercharge your online presence with our strategic digital marketing services. Drive traffic, generate leads, and boost sales today!",
      img: require("../../images/home/digital marketing.svg").default,
    },

    // {
    //   id: 5,
    //   title: "Training",
    //   img: require('../image/home/training-minidreams.webp').default,
    //   desc: (
    //     <span>
    //    `&quot;`Revolutionize Your Business with Expert Analysis, Innovative Solutions, and Complex Problem-Solving`&quot;`
    //     </span>
    //   ),
    // },
  ];
}

export function OurReviewList() {
  return [
    {
      id: 1,
      title: "Wonderful app ",
      by: "Ravi, Founder & CEO",
      alt: "Excellent feedback from happy customer of itboomi - Mobile App and Website Development Company in Coimbatore",
      Company: "Vijaya Printed Gifts Mancherial",
      backgroundColor: "#020205",
      img: require("../../images/home/googlerating.svg").default,
      desc: '"I have received a wonderful app developed by mini dreams,Thanks team."',
    },
    {
      id: 2,
      by: "Imran, Founder & CEO",
      alt: "Highly recommended by delighted client of itboomi - Mobile App and Website Development Company in Coimbatore",
      Company: "bicads digital Pvt. Ltd.",
      title: "Team of professionals",
      backgroundColor: "#020205",
      desc: '"Team of professionals. A very good experience on the Mobile App development project done by them. Will surely work with them again."',
      img: require("../../images/home/googlerating.svg").default,
    },

    {
      id: 3,
      by: "BURZIN UNWALLA, Senior Project Manager",
      Company: "Moving Pixels Pvt. Ltd.",
      alt: "Great experience shared by contented customer of itboomi - Mobile App and Website Development Company in Coimbatore",
      title: "A big shoutout to Deepak",
      backgroundColor: "#020205",
      desc: '"A big shoutout to Deepak from MiniDreams LLP who delivered the mobils application we needed as per our expectations."',
      img: require("../../images/home/googlerating.svg").default,
    },
    {
      id: 4,
      title: "Incredible Experience",
      by: "Ramki, Founder & CEO",
      alt: "Positive review from satisfied client of itboomi - Mobile App and Website Development Company in Coimbatore",
      Company: "FigForce",
      backgroundColor: "#020205",
      img: require("../../images/home/googlerating.svg").default,
      desc: '"I had an incredible experience working with the app development team. Their expertise, professionalism, and attention to detail were truly exceptional. They understood my requirements, delivered a well-designed app, and provided excellent support throughout the process. I highly recommend their services for anyone seeking top-quality app development."',
    },
    {
      id: 5,
      by: "Vasantha Kumar, Founder",
      Company: "SWASTIK PROMOTERS PVT LTD",
      title: "Really Pro",
      alt: "Five-star rating from impressed client of itboomi - Mobile App and Website Development Company in Coimbatore",
      backgroundColor: "#020205",
      desc: '"Really Pro.. Exceeds our expectations... On time and great work.."',
      img: require("../../images/home/googlerating.svg").default,
    },

    {
      id: 6,
      by: "Aalok, Founder & CEO",
      Company: "RP Digi Peaks",
      title: "Tremendous Work",
      alt: "Outstanding service highlighted by appreciative customer of itboomi - Mobile App and Website Development Company in Coimbatore",
      backgroundColor: "#020205",
      desc: '"I have enquired for website revamping, you guys have done tremendous work for us. Thank you so much for your services. Wish you all the best Mini Dreams!"',
      img: require("../../images/home/googlerating.svg").default,
    },
    {
      id: 7,
      by: "Gokul Kumar, Founder & CEO",
      Company: "Funlax",
      title: "I was more impressed",
      alt: "Outstanding service highlighted by appreciative customer of itboomi - Mobile App and Website Development Company in Coimbatore",
      backgroundColor: "#020205",
      desc: '"I recently had the pleasure of working with Minidreams for the development of a mobile app, and I was more impressed with the experience. From start to finish, their team exhibited professionalism, expertise, and a genuine commitment to delivering exceptional results."',
      img: require("../../images/home/googlerating.svg").default,
    },
  ];
}

export function Ourtechs() {
  return [
    {
      id: 2,
      title: "Frontend Development",
      technologies: [
        {
          name: "JavaScript",
          icon: require("../../images/tech/javascript.svg").default,
        },

        {
          name: "React JS",
          icon: require("../../images/tech/react.svg").default,
        },

        {
          name: "TypeScript",
          icon: require("../../images/tech/typescript.svg").default,
        },
        {
          name: "Bootstrap",
          icon: require("../../images/tech/bootstrap.svg").default,
        },
      ],
    },
    {
      id: 3,
      title: "Mobile App Development",
      technologies: [
        {
          name: "Android",
          icon: require("../../images/tech/android.svg").default,
        },
        {
          name: "Flutter",
          icon: require("../../images/tech/flutter.svg").default,
        },
        {
          name: "React Native",
          icon: require("../../images/tech/react.svg").default,
        },
        // {
        //   name: "Swift",
        //   icon: require("../../images/tech/swift.svg").default,
        // },
        {
          name: "Kotlin",
          icon: require("../../images/tech/kotlin.svg").default,
        },
      ],
    },
    {
      id: 4,
      title: "Backend Development",
      technologies: [
        // {
        //   name: "Laravel",
        //   icon: require("../../images/tech/laravel.svg").default,
        // },
        {
          name: "Node.js",
          icon: require("../../images/tech/nodejs.svg").default,
        },
        // { name: "PHP", icon: require("../../images/tech/php.svg").default },
        {
          name: "MongoDB",
          icon: require("../../images/tech/mongodb.svg").default,
        },
        {
          name: "MySQL",
          icon: require("../../images/tech/mysql.svg").default,
        },
        // {
        //   name: "PostgreSQL",
        //   icon: require("../../images/tech/postgresql.svg").default,
        // },
        {
          name: "Firebase",
          icon: require("../../images/tech/firebase.svg").default,
        },
        {
          name: "Next.js",
          icon: require("../../images/tech/nextjs.svg").default,
        },
        // {
        //   name: "Redux",
        //   icon: require("../../images/tech/redux.svg").default,
        // },
      ],
    },
    {
      id: 1,
      title: "Design and Prototyping",
      technologies: [
        {
          name: "Illustrator",
          icon: require("../../images/tech/adobeillustaion.svg").default,
        },
        {
          name: "Figma",
          icon: require("../../images/tech/figma.svg").default,
        },
        {
          name: "Photoshop",
          icon: require("../../images/tech/photoshop.svg").default,
        },
      ],
    },
    {
      id: 5,
      title: "Cloud Services and Deployment",
      technologies: [
        {
          name: "AWS",
          icon: require("../../images/tech/aws.svg").default,
        },
        {
          name: "Azure",
          icon: require("../../images/tech/azure.svg").default,
        },
        {
          name: "Google Cloud",
          icon: require("../../images/tech/googlecloud.svg").default,
        },
        {
          name: "Docker",
          icon: require("../../images/tech/docker.svg").default,
        },
      ],
    },
    {
      id: 6,
      title: "Version Control and Collaboration",
      technologies: [
        { name: "Git", icon: require("../../images/tech/git.svg").default },
        {
          name: "GitHub",
          icon: require("../../images/tech/github.svg").default,
        },
        {
          name: "GitLab",
          icon: require("../../images/tech/gitlab.svg").default,
        },
        {
          name: "Bitbucket",
          icon: require("../../images/tech/bitbucket.svg").default,
        },
      ],
    },
    {
      id: 7,
      title: "Development Tools",
      technologies: [
        { name: "Jira", icon: require("../../images/tech/jira.svg").default },
        {
          name: "Postman",
          icon: require("../../images/tech/postman.svg").default,
        },
        { name: "Npm", icon: require("../../images/tech/npm.svg").default },
      ],
    },
    {
      id: 9,
      title: "Digital Marketing",
      technologies: [
        {
          name: "Google Ads",
          icon: require("../../images/tech/gads.svg").default,
        },
        {
          name: "Meta Ads",
          icon: require("../../images/tech/meta.svg").default,
        },
        {
          name: "Google Analytics",
          icon: require("../../images/tech/analytics.svg").default,
        },
      ],
    },
    {
      id: 8,
      title: "UI Frameworks and Libraries",
      technologies: [
        {
          name: "MUI (Material-UI)",
          icon: require("../../images/tech/mui.svg").default,
        },
      ],
    },
    // Add other categories and their respective technologies in a similar manner...
  ];
}

export function Teams() {
  return [
    {
      id: 1,
      title: "Deepak Kumar",
      by: "Founder & CEO",
      img: require("../../images/team/deepak.png"),
      instagram: "",
      twitter: "",
      linkedin: "",
      alt: "Deepak Kumar, Founder & CEO at itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 2,
      title: "Maheswari",
      by: "Founder & CBDO",
      img: require("../../images/team/mahe.png"),
      instagram: "",
      twitter: "",
      linkedin: "",
      alt: "Maheswari, Founder & CBDO at itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 3,
      title: "Kishore",
      by: "Fullstack Developer",
      img: require("../../images/team/kishore.png"),
      instagram:
        "https://www.instagram.com/kishore_r_i_o?igsh=MXB1YXk0czliYjcwbA==",
      twitter: "",
      linkedin: "https://www.linkedin.com/in/kishore-p-6a8b9a227",
      alt: "Kishore, Fullstack Developer at itboomi - Mobile App and Website Development Company in Coimbatore",
    },

    {
      id: 4,
      title: "Ruban Kumar",
      by: "Fullstack Developer\nMobile", // Using "\n" for line break
      img: require("../../images/team/ruban.png"),
      alt: "Ruban Kumar, Fullstack Developer at itboomi - Mobile App and Website Development Company in Coimbatore",
      instagram:
        "https://www.instagram.com/kaththi_ruban62?igsh=eHlneXZnYXl0NXIz",
      twitter: "https://x.com/Ruban6252?t=8QUPsoCdQaDJWyKWjJgd-A&s=09",
      linkedin:
        "https://www.linkedin.com/in/ruban-kumar-b-4369b6224?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
    {
      id: 5,
      title: "Arun Prince",
      by: "Fullstack Developer",
      img: require("../../images/team/arun.png"),
      instagram: "",
      alt: "Arun Prince, Fullstack Developer at itboomi - Mobile App and Website Development Company in Coimbatore",

      twitter: "https://twitter.com/prince8870?s=09",
      linkedin: "https://www.linkedin.com/in/arun-prince-developer",
    },

    {
      id: 6,
      title: "Guru Prasath",
      by: "UI/UX Designer",
      alt: "Guru Prasath, UI/UX Designer at itboomi - Mobile App and Website Development Company in Coimbatore",
      img: require("../../images/team/guru.png"),
      instagram:
        "https://www.instagram.com/g.u.r.u______?igsh=MWduaDR3aDY5Y3hzNQ==",
      twitter: "",
      linkedin: "https://www.linkedin.com/in/guruprasath-v-ab22a0286",
    },

    {
      id: 7,
      title: "Saravanan",
      by: "Fullstack Developer",
      alt: "Saravanan, Fullstack Developer at itboomi - Mobile App and Website Development Company in Coimbatore",

      img: require("../../images/team/saravanan.png"),
      instagram: "",
      twitter: "",
      linkedin: "https://www.linkedin.com/in/saravanan-webdeveloper",
    },

    {
      id: 7,
      title: "Prathiksha",
      by: "Digital Marketer",
      alt: "Prathksha, Digital Marketing at itboomi - Mobile App and Website Development Company in Coimbatore",
      img: require("../../images/team/prathksha.png"),
      instagram: "",
      twitter: "",
      linkedin: "https://www.linkedin.com/in/saravanan-webdeveloper",
    },

    // {
    //   id: 5,
    //   title: "Training",
    //   img: require('../image/home/training-minidreams.webp').default,
    //   desc: (
    //     <span>
    //    `&quot;`Revolutionize Your Business with Expert Analysis, Innovative Solutions, and Complex Problem-Solving`&quot;`
    //     </span>
    //   ),
    // },
  ];
}

export function WhyUs() {
  return [
    {
      id: 1,
      title: "Who We Are",
      desc: "Welcome to ItBoomi, your gateway to a transformative digital journey. We are a distinguished company specializing in website development, app creation, and graphics design. Recognized worldwide for our excellence, we proudly stand at the forefront of the industry, partnering with clients on innovative projects that push the boundaries of design and technology.",
    },

    {
      id: 2,
      title: "What We Do",
      desc: "At ItBoomi, we specialize in shaping the digital future through our expertise in website development, app creation, and graphics design. We invite you to initiate a conversation about your project by reaching out to us via email. Our team excels in crafting tailored digital solutions that transform your concepts into impactful realities. Experience the power of innovative thinking and unparalleled quality with ItBoomi.",
    },
    {
      id: 3,
      title: "Making Amazing Stuff",
      desc: "Step into the realm of extraordinary possibilities with ItBoomi as your trusted guide through the digital landscape. We specialize in empowering forward-thinking individuals and businesses to reach unparalleled heights of success. Harnessing our expertise in website and app development alongside stellar graphics design, we transform your visionary ideas into remarkable digital masterpieces. Experience innovation at its finest with ItBoomi, where we make the extraordinary a reality.",
    },

    // {
    //   id: 5,
    //   title: "Training",
    //   img: require('../image/home/training-minidreams.webp').default,
    //   desc: (
    //     <span>
    //    `&quot;`Revolutionize Your Business with Expert Analysis, Innovative Solutions, and Complex Problem-Solving`&quot;`
    //     </span>
    //   ),
    // },
  ];
}

export function Process() {
  return [
    {
      id: 1,
      title: "🛠️ App Development",
      desc: "🛠️",
    },

    {
      id: 2,
      title: "✅ Quality Assurance",
      desc: "✅",
    },
    {
      id: 3,
      title: "💰 Monetization",
      desc: "💰",
    },
    {
      id: 4,
      title: "📈 Growth",
      desc: "📈",
    },
    {
      id: 5,
      title: "📊 Conversion Rate Optimization",
      desc: "📊",
    },
    {
      id: 5,
      title: "📋 Management",
      desc: "📋",
    },

    // {
    //   id: 5,
    //   title: "Training",
    //   img: require('../image/home/training-minidreams.webp').default,
    //   desc: (
    //     <span>
    //    `&quot;`Revolutionize Your Business with Expert Analysis, Innovative Solutions, and Complex Problem-Solving`&quot;`
    //     </span>
    //   ),
    // },
  ];
}

export function HomeProcess() {
  return [
    {
      id: 1,
      title: "App Development",
      desc: "🛠️",
    },

    {
      id: 2,
      title: "Quality Assurance",
      desc: "✅",
    },
    {
      id: 3,
      title: "Monetization",
      desc: "💰",
    },
    {
      id: 4,
      title: "Growth",
      desc: "📈",
    },
    {
      id: 5,
      title: "Conversion Rate Optimization",
      desc: "📊",
    },
    {
      id: 5,
      title: "Management",
      desc: "📋",
    },

    // {
    //   id: 5,
    //   title: "Training",
    //   img: require('../image/home/training-minidreams.webp').default,
    //   desc: (
    //     <span>
    //    `&quot;`Revolutionize Your Business with Expert Analysis, Innovative Solutions, and Complex Problem-Solving`&quot;`
    //     </span>
    //   ),
    // },
  ];
}

export function HomeQuestion() {
  return [
    {
      id: 1,
      title: "Elevate My Digital Presence?",
      desc: "🛠️",
    },

    {
      id: 2,
      title: "Develop a Cutting-Edge Web or Mobile App?",
      desc: "✅",
    },
    {
      id: 3,
      title: "I Streamline My Business Processes?",
      desc: "💰",
    },
    {
      id: 4,
      title: "Scale My Digital Offerings?",
      desc: "📈",
    },
    {
      id: 5,
      title: "Leverage Data to Inform My Decisions?",
      desc: "📊",
    },
    {
      id: 6,
      title: "Stay Ahead of the Competition?",
      desc: "📋",
    },

    {
      id: 7,
      title: "Provide a Seamless Customer Experience?",
      desc: "📋",
    },

    // {
    //   id: 5,
    //   title: "Training",
    //   img: require('../image/home/training-minidreams.webp').default,
    //   desc: (
    //     <span>
    //    `&quot;`Revolutionize Your Business with Expert Analysis, Innovative Solutions, and Complex Problem-Solving`&quot;`
    //     </span>
    //   ),
    // },
  ];
}
export function About(name, profession) {
  return [
    {
      id: 1,
      title: "Mission",
      img: require("../../images/home/mission.svg").default,
      desc: `Our mission at Itboomi is to revolutionize the digital landscape through innovative solutions that empower businesses like yours, <span style="color: #fc4985">${name}</span>, to thrive and succeed. We are committed to creating transformative experiences that drive tangible improvements in our clients' lives, anticipating opportunities, and exceeding expectations.`,
    },
    {
      id: 2,
      title: "Vision",
      img: require("../../images/home/vision.svg").default,
      desc: `At Itboomi, our vision is to be the leading provider of client-centric digital solutions, setting new standards of excellence in the industry. We aspire to create a tech-driven future where businesses like <span style="color: #fc4985">${profession}</span> can harness the power of innovation to achieve sustainable growth and success.`,
    },
    {
      id: 3,
      title: "Innovation",
      img: require("../../images/home/innovation.svg").default,
      desc: `Innovation is at the core of everything we do at Itboomi. We strive to push boundaries, challenge the status quo, and pioneer cutting-edge technologies that transform the way businesses like yours, <span style="color: #fc4985">${name}</span>, operate. Our commitment to innovation drives us to constantly evolve, adapt, and deliver solutions that exceed our clients' expectations.`,
    },
    {
      id: 4,
      title: "Client Focus",
      img: require("../../images/home/heart.svg").default,
      desc: `At Itboomi, being client-centric is not just a philosophy but a way of life. We place our clients, like you, <span style="color: #fc4985">${name}</span>, at the heart of everything we do, tailoring our solutions to meet your unique needs and challenges as a <span style="color: #fc4985">${profession}</span>. Our client-centric approach ensures that we not only meet but exceed your expectations, building long-lasting partnerships based on trust, transparency, and mutual success.`,
    },
  ];
}

export function Clients() {
  return [
    {
      id: 1,
      title: "NSP TEX",
      img: require("../../images/clients/nsptex.svg").default,
      alt: "NSP TEX: Valued client of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 2,
      title: "Spotrado Technologies",
      desc: "Let's discuss the digital future! Reach out to us via email to start a conversation about your website, app, or graphics design project. At ItBoomi, we thrive on unique digital approaches, turning your concepts into high-quality, impactful digital solutions.",
      img: require("../../images/clients/spotrado.svg").default,
      alt: "Spotrado Technologies: Trusted customer of itboomi - Web Development Company in Coimbatore",
    },
    {
      id: 3,
      title: "Makers Studio",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/makerstudio.svg").default,
      alt: "Makers Studio: Esteemed client of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 4,
      title: "Doers Tech",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/doerstech.svg").default,
      alt: "Doers Tech: Respected customer of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 5,
      title: "PhotoGrator",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/photogrator.svg").default,
      alt: "PhotoGrator: Admired client of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 6,
      title: "Moving Pixel",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/movingpixels.svg").default,
      alt: "Moving Pixel: Appreciated customer of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 7,
      title: "BuyAntivirusKey",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/buyantiviruskey.svg").default,
      alt: "BuyAntivirusKey: Recognized client of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 8,
      title: "Arrow Marketing",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/arrowmarketing.svg").default,
      alt: "Arrow Marketing: Acknowledged customer of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 9,
      title: "Suwastika Promoters",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/suwastika.svg").default,
      alt: "Suwastika Promoters: Honored client of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 10,
      title: "Budget App Studio",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/budjetappstudios.svg").default,
      alt: "Budget App Studio: Trusted customer of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 11,
      title: "RP Digi Peaks",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/rpdigipeaks.svg").default,
      alt: "RP Digi Peaks: Esteemed client of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 12,
      title: "Frames Factory",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/framesfactory.svg").default,
      alt: "Frames Factory: Respected customer of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 13,
      title: "C2G",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/c2g.svg").default,
      alt: "C2G: Admired client of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 14,
      title: "PernodRicard",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/PernodRicard.svg").default,
      alt: "PernodRicard: Appreciated customer of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 15,
      title: "Mirthu Creations",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/Mirthucreations Logo.svg").default,
      alt: "Mirthu Creations: Appreciated customer of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
    {
      id: 16,
      title: "Jayaanthi Treaders",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",
      img: require("../../images/clients/jayaanthitraderslogo.svg").default,
      alt: "Jayaanthi Traders: Appreciated customer of itboomi - Mobile App and Website Development Company in Coimbatore",
    },
  ];
}

export function PortfolioData() {
  return [
    {
      id: 1,
      category: ["Web", "UI/UX"],
      class: "4",
      title: "Mirthu Creations",
      image: require("../../images/projects/Updated Images Web/MirthuCreations.png"),
      client: "sds",
      location: "loca",
      link: "https://mirthucreations.com",
      enableButton: true,
      projecttype: "Photo",
      weblink: "https://mirthucreations.com",
      duration: "sd",
      description: "Photo",
      desc: "Mirthu Creation is a service-oriented company specializing in design, branding, and digital marketing. Their website showcases their innovative solutions for creating impactful brand identities and effective digital strategies. Explore the website to see how Mirthu Creation can elevate your brand and drive success.",
      bottomdesc: "",
      alt: "Web & UI/UX Design for Mirthu Creations by itboomi",
    },
    {
      id: 1,
      category: ["UI/UX"],
      category: ["Web", "UI/UX"],
      class: "4",
      title: "Jayaanthi Traders",
      image: require("../../images/projects/Updated Images Web/JayaanthiTraders.png"),
      client: "sds",
      location: "loca",
      link: "https://jayaanthitraders.com",
      enableButton: true,
      projecttype: "Photo",
      weblink: "https://jayaanthitraders.com",
      duration: "sd",
      description: "Photo",
      desc: "Jayanthi Traders provides top-quality cotton waste solutions, leveraging over 25 years of experience. Their website showcases premium long-staple cotton waste, including Giza and Supima, with a focus on prompt delivery across India. Visit Jayanthi Traders today to see how their reliable and excellent service can meet your cotton waste needs.",
      bottomdesc: "",
      alt: "Web & UI/UX Design for Jayaanthi Traders by itboomi",
    },

    {
      id: 1,
      category: ["UI/UX"],
      category: ["Web", "UI/UX"],
      class: "4",
      title: "Photogrator Landing Page",
      image: require("../../images/projects/Updated Images Web/Photogrator Web.png"),
      client: "sds",
      location: "loca",
      link: "https://photogrator.com",
      enableButton: true,
      projecttype: "Photo",
      weblink: "https://photogrator.com",
      duration: "sd",
      description: "Photo",
      desc: "Photogrator simplifies event photo-sharing with effortless capture, organization, and real time updates. Enjoy a user-friendly interface for seamless, personalized photo experiences. Capture your moments and explore the website to start sharing memories today!",
      bottomdesc: "",
      alt: "Web & UI/UX Design for Photogrator by itboomi",
    },

    {
      id: 1,
      category: ["Mobile App", "UI/UX"],
      class: "4",
      title: "PhotoGrator",
      image: require("../../images/projects/Updated Images/Photogrator.png"),
      playstore: require("../../images/projects/playstore.svg").default,
      appstore: require("../../images/projects/appstore.svg").default,
      playlink: "https://play.google.com/store/apps/details?id=com.photogrator",
      ioslink: "",
      weblink: "https://photogrator.com/",
      client: "sds",
      location: "loca",
      projecttype: "Photo",
      duration: "sd",
      description: "Photo",
      desc: "simplified photo-sharing for events. Capture and upload memories effortlessly, organize by occasion, and share seamlessly with real-time updates. Enjoy a user-friendly interface designed for quick, personalized event photo experiences.",
      bottomdesc: "",
      alt: "Mobile App & UI/UX Development for PhotoGrator by itboomi",
    },
    {
      id: 15,
      category: ["Mobile App"],
      class: "4",
      title: "Adhiban",
      image: require("../../images/projects/Updated Images/Adhiban.png"),
      client: "sds",
      location: "loca",
      playlink: "https://play.google.com/store/apps/details?id=com.adhibanapp",
      ioslink: "",
      weblink: "https://photogrator.com/",
      playstore: require("../../images/projects/playstore.svg").default,
      appstore: require("../../images/projects/appstore.svg").default,
      projecttype: "Photo",
      desc: "Adhiban, your go-to small finance app for hassle-free loans. Experience quick and convenient lending solutions tailored to your needs. With Adhiban, securing a loan is simple, efficient, and designed to empower your financial goals. Download the app today and take a step towards financial ease.",
      duration: "sd",
      description: "Photo",
      bottomdesc: "",
      alt: "Web & UI/UX Design for Mindrelax Landing Page by itboomi",
    },
    {
      id: 2,
      category: ["UI/UX"],
      class: "4",
      title: "FleetMango",
      image: require("../../images/projects/Updated Images/fleetmango.png"),
      client: "sds",
      location: "loca",
      projecttype: "Photo",
      duration: "sd",
      description: "Photo",
      desc: "Unleash the extraordinary! ItBoomi is your partner in navigating the digital landscape, helping forward-thinking clients achieve unparalleled success. Let's transform your ideas into remarkable digital masterpieces with our website and app development expertise combined with stellar graphics design.",

      bottomdesc:
        "FleetMango is a comprehensive solution for managing vehicles and cargo. Streamline your fleet operations with real-time tracking, efficient route planning, and cargo management capabilities. Enhance visibility, optimize logistics, and ensure smooth transportation processes for a more productive and cost-effective operation.",
      alt: "UI/UX Design for FleetMango by itboomi",
    },
    {
      id: 3,
      category: ["Mobile App", "UI/UX"],
      class: "4",
      title: "MindRelax",
      image: require("../../images/projects/Updated Images/mindrelax.png"),
      client: "sds",
      location: "loca",
      projecttype: "Photo",
      playlink: "https://play.google.com/store/apps/details?id=com.mindrelax",
      ioslink: "",
      weblink: "https://photogrator.com/",
      playstore: require("../../images/projects/playstore.svg").default,
      appstore: require("../../images/projects/appstore.svg").default,
      desc: "MindRelax, an app designed for relaxation with expert guidance. Connect with mentors and specialists to experience personalized relaxation sessions. Whether it's meditation, mindfulness, or stress relief, MindRelax provides a tailored approach to help you unwind and find tranquility. Prioritize your well-being with our accessible and calming mentorship platform.",
      duration: "sd",
      description: "Photo",
      bottomdesc: "",
      alt: "Mobile App & UI/UX Design for MindRelax by itboomi",
    },
    {
      id: 4,
      category: ["Mobile App", "UI/UX"],
      class: "4",
      title: "Treles Signals",
      image: require("../../images/projects/Updated Images/trelessignals.png"),
      client: "sds",
      location: "loca",
      playlink: "https://play.google.com/store/apps/details?id=com.treles",
      ioslink: "",
      weblink: "https://treles.info/",
      desc: "Treles Signals, your go-to app for trading signals. Stay ahead in the market with timely signals for trading opportunities. Our app delivers expert insights, empowering you with real-time information to make informed trading decisions. Maximize your trading potential with Treles Signals – your key to strategic and profitable investments.",
      projecttype: "Photo",
      playstore: require("../../images/projects/playstore.svg").default,
      appstore: require("../../images/projects/appstore.svg").default,
      duration: "sd",
      description: "Photo",
      bottomdesc: "",
      alt: "Mobile App & UI/UX Design for Treles Signals by itboomi",
    },
    {
      id: 5,
      category: ["Mobile App", "UI/UX"],
      class: "4",
      title: "Shivsrushti Museum Audio App",
      image: require("../../images/projects/Updated Images/shivsrushti.png"),
      client: "sds",
      location: "loca",
      desc: "Shivsrushti Museum App – Elevate your museum experience with our innovative audio tour feature. Immerse yourself in rich narratives and insights as you explore exhibits. Enhance your visit with a seamless blend of technology and culture, making your journey through Shivsrushti Museum both educational and captivating.",
      projecttype: "Photo",
      duration: "sd",
      description: "Photo",
      bottomdesc: "",
      alt: "Mobile App & UI/UX Design for Shivsrushti Museum Audio App by itboomi",
    },
    {
      id: 6,
      category: ["UI/UX"],
      class: "4",
      title: "ChillTalk",
      image: require("../../images/projects/chilltalk.png"),
      client: "sds",
      location: "loca",
      desc: "ChillTalk: Connect, Share, Chill. This app lets you seamlessly connect with friends and mentors through audio calls. Experience genuine conversations and advice in a relaxed setting, making every call a moment to unwind and connect with those who matter most. Download ChillTalk for a new level of meaningful connections.",
      projecttype: "Photo",
      duration: "sd",
      description: "Photo",
      bottomdesc: "",
      alt: "UI/UX Design for ChillTalk by itboomi",
    },
    {
      id: 7,
      category: ["Web", "UI/UX"],
      class: "4",
      title: "Mindrelax Landing Page",
      image: require("../../images/projects/mindrelaxweb.png"),
      client: "sds",
      location: "loca",
      projecttype: "Photo",
      weblink: "https://www.mindrelax.in/",
      desc: "MindRelax, an app designed for relaxation with expert guidance. Connect with mentors and specialists to experience personalized relaxation sessions. Whether it's meditation, mindfulness, or stress relief, MindRelax provides a tailored approach to help you unwind and find tranquility. Prioritize your well-being with our accessible and calming mentorship platform.",
      duration: "sd",
      description: "Photo",
      bottomdesc: "",
      link: "https://google.com",
      enableButton: true,
      alt: "Web & UI/UX Design for Mindrelax Landing Page by itboomi",
    },

    {
      id: 8,
      category: ["Web", "UI/UX"],
      class: "4",
      title: "NSP TEX Workflow ERP",
      image: require("../../images/projects/Updated Images/NSP.png"),
      client: "sds",
      location: "loca",
      projecttype: "Photo",
      duration: "sd",
      desc: "NSP Tex, your all-in-one garment management app. Streamline your textile business with features like inventory tracking, order management, and seamless communication. With NSP Tex, stay in control of your garment production, from raw materials to finished products. Elevate your efficiency and productivity in the textile industry with NSP Tex.",
      description: "Photo",
      bottomdesc: "",
      alt: "Web & UI/UX Design for NSP TEX Workflow ERP by itboomi",
    },
    {
      id: 9,
      category: ["Web", "UI/UX"],
      class: "4",
      title: "Arrow Marketing",
      image: require("../../images/projects/Updated Images Web/ArrowMarketing Web.png"),
      client: "sds",
      location: "loca",
      link: "https://google.com",
      enableButton: true,
      projecttype: "Photo",
      weblink: "https://arrowmarketing.co.in/",
      duration: "sd",
      description: "Photo",
      desc: "Welcome to Arrow Marketing, your trusted partner in marketing high-quality transformers and machinery products. Explore our landing page for a glimpse into cutting-edge solutions designed to power industries. From transformers to advanced machinery, we specialize in delivering excellence. Discover how Arrow Marketing can elevate your business with top-tier products and strategic marketing expertise.",
      bottomdesc: "",
      alt: "Web & UI/UX Design for Arrow Marketing by itboomi",
    },

    {
      id: 10,
      category: ["Mobile App", "UI/UX"],
      class: "4",
      title: "BuyAntiviruskey",
      image: require("../../images/projects/Updated Images/buyantivirus.png"),
      client: "sds",
      location: "loca",
      projecttype: "Photo",
      playlink:
        "https://play.google.com/store/apps/details?id=com.buyantiviruskey",
      ioslink: "",
      weblink: "https://treles.info/",
      duration: "sd",
      playstore: require("../../images/projects/playstore.svg").default,
      appstore: require("../../images/projects/appstore.svg").default,
      description: "Photo",
      bottomdesc: "",
      desc: "We've developed a mobile app for BuyAntivirusKey.com, enhancing their customer experience with convenient access to antivirus solutions on the go. Explore and purchase antivirus keys seamlessly through our user-friendly app, ensuring your device's security is just a tap away.",
      alt: "Mobile App & UI/UX Design for BuyAntiviruskey by itboomi",
    },
    // {
    //   id: 11,
    //   category: ["Graphic Design"],
    //   class: "4",
    //   title: "Suwastika Promoters Logo",
    //   image: require("../../images/projects/suwastika.png"),
    //   location: "loca",
    //   projecttype: "Photo",
    //   duration: "sd",
    //   desc: "",
    //   description: "Photo",
    //   bottomdesc: "",
    //   alt: "Graphic Design for Suwastika Promoters Logo by itboomi",
    // },
    // {
    //   id: 12,
    //   category: ["Graphic Design"],
    //   class: "4",
    //   title: "PhotoGrator Logo",
    //   image: require("../../images/projects/photogratorlogo.png"),
    //   client: "sds",
    //   location: "loca",
    //   projecttype: "Photo",
    //   duration: "sd",
    //   desc: "",
    //   description: "Photo",
    //   bottomdesc: "",
    //   alt: "Graphic Design for PhotoGrator Logo by itboomi",
    // },
    // {
    //   id: 12,
    //   category: ["Graphic Design"],
    //   class: "4",
    //   title: "Momskin Logo",
    //   image: require("../../images/projects/momskin.png"),
    //   client: "sds",
    //   location: "loca",
    //   projecttype: "Photo",
    //   duration: "sd",
    //   desc: "",
    //   description: "Photo",
    //   bottomdesc: "",
    //   alt: "Graphic Design for Momskin Logo by itboomi",
    // },
    // {
    //   id: 13,
    //   category: ["Graphic Design"],
    //   class: "4",
    //   title: "Funlax Logo",
    //   image: require("../../images/projects/funlax.jpeg"),
    //   client: "sds",
    //   location: "loca",
    //   projecttype: "Photo",
    //   duration: "sd",
    //   desc: "",
    //   description: "Photo",
    //   bottomdesc: "",
    //   alt: "Graphic Design for Funlax Logo by itboomi",
    // },
    // {
    //   id: 14,
    //   category: ["Graphic Design"],
    //   class: "4",
    //   title: "MiniDreams Logo",
    //   image: require("../../images/projects/minidreams.jpeg"),
    //   client: "sds",
    //   location: "loca",
    //   projecttype: "Photo",
    //   duration: "sd",
    //   desc: "",
    //   description: "Photo",
    //   bottomdesc: "",
    //   alt: "Graphic Design for MiniDreams Logo by itboomi",
    // },
  ];
}

// export function OurPortfolioList() {
//   return [
//     {
//       id: 1,
//       title: "Pernod Ricard",
//       img: require('../image/portfolio/minidreams pernod ricard.webp'),
//       desc: '"Transform Your App with State-of-the-Art Technologies for Unmatched Security and User Experience"',
//       works: [
//         "Research",
//         "UI/UX",
//         "App",
//       ],
//       color: "#AACFFF",
//       textColor: "#fff"
//     },
//     {
//       id: 2,
//       title: "Buy Antivirus Key",
//       img: require('../image/portfolio/minidreams buy antivirus key.webp'),
//       desc: '"Transform Your App with State-of-the-Art Technologies for Unmatched Security and User Experience"',
//       works: [
//         "Research",
//         "UI/UX",
//         "App",
//       ],
//       color: "#FFF2A7",
//       textColor: "#000"
//     },
//     {
//       id: 3,
//       title: "Arrow Marketing",
//       img: require('../image/portfolio/minidreams arrow marketing.webp'),
//       desc: '"Transform Your App with State-of-the-Art Technologies for Unmatched Security and User Experience"',
//       works: [
//         "UI/UX",
//         "Web",
//       ],
//       color: "#BEFFE3",
//       textColor: "#fff"
//     },
//     {
//       id: 4,
//       title: "Foto Cam",
//       img: require('../image/portfolio/minidreams foto cam.webp'),
//       desc: '"Transform Your App with State-of-the-Art Technologies for Unmatched Security and User Experience"',
//       works: [
//         "UI/UX",
//         "App",
//       ],
//       color: "#FFC2C2",
//       textColor: "#fff"
//     },
//     {
//       id: 5,
//       title: "c2g",
//       img: require('../image/portfolio/minidreams c2g.webp'),
//       desc: '"Transform Your App with State-of-the-Art Technologies for Unmatched Security and User Experience"',
//       works: [
//         "UI/UX",
//         "App",
//       ],
//       color: "#C4ABFF",
//       textColor: "#fff"
//     },
//     {
//       id: 6,
//       title: "Shivsrushti Historical theme park",
//       img: require('../image/portfolio/minidreams Moving Pixels.webp'),
//       desc: '"Transform Your App with State-of-the-Art Technologies for Unmatched Security and User Experience"',
//       works: [
//         "UI/UX",
//         "App",
//       ],
//       color: "#9774F2",
//       textColor: "#fff"
//     },
//   ];
// }

// export function OurTechList() {
//   return [
//     {
//       id: 1,
//       title: "Native Android",
//       img: ('../image/techs/minidreams_android.webp'),
//     },
//     {
//       id: 2,
//       title: "Kotlin",
//       img: require('../image/techs/minidreams_kotlin.webp'),
//     },
//     {
//       id: 3,
//       title: "Java",
//       img: require('../image/techs/minidreams_java.webp'),
//     },
//     {
//       id: 4,
//       title: "Java script",
//       img: require('../image/techs/minidreams_javascript.webp'),
//     },
//     {
//       id: 5,
//       title: "React Js",
//       img: require('../image/techs/minidreams_react.webp'),
//     },
//     {
//       id: 6,
//       title: "PHP",
//       img: require('../image/techs/minidreams_php.webp'),
//     },
//     {
//       id: 7,
//       title: "Mongo DB",
//       img: require('../image/techs/minidreams_mongodb.webp'),
//     },
//     {
//       id: 8,
//       title: "Firebase",
//       img: require('../image/techs/minidreams_firebase.webp'),
//     },
//     {
//       id: 9,
//       title: "AWS",
//       img: require('../image/techs/minidreams_aws.webp'),
//     },
//     {
//       id: 10,
//       title: "Google Cloud Platform",
//       img: require('../image/techs/minidreams_google-cloud.webp'),
//     },
//     {
//       id: 11,
//       title: "MySQL",
//       img: require('../image/techs/minidreams_mysql.webp'),
//     },
//     {
//       id: 12,
//       title: "Node Js",
//       img: require('../image/techs/minidreams_nodejs.webp'),
//     },
//     {
//       id: 13,
//       title: "React Native",
//       img: require('../image/techs/minidreams_react.webp'),
//     },
//     {
//       id: 14,
//       title: "Docker",
//       img: require('../image/techs/minidreams_docker.webp'),
//     },
//     {
//       id: 15,
//       title: "Laravel",
//       img: require('../image/techs/minidreams_laravel.webp'),
//     },
//     {
//       id: 16,
//       title: "Mui",
//       img: require('../image/techs/minidreams_mui.webp'),
//     },
//     {
//       id: 17,
//       title: "Webrtc",
//       img: require('../image/techs/minidreams_webrtc.webp'),
//     },

//   ];
// }

// export function OurAppProcess() {
//   return [
//     {
//       id: 1,
//       title: "Discovery and Planning",
//       img: require('../image/flow/minidreams_discover.svg').default,
//       img1: require('../image/flow/minidreams_planning.svg').default,
//       desc: '"We collaborate with you to gather requirements, define project goals, and create a solid foundation for development."',
//     },
//     {
//       id: 2,
//       title: "Design and Prototyping",
//       img: require('../image/flow/minidreams_designing.svg').default,
//       img1: require('../image/flow/minidreams_prototyping.svg').default,
//       desc: '" Our skilled designers create intuitive and visually appealing app designs, which are then transformed into interactive prototypes for feedback and validation."',
//     },
//     {
//       id: 3,
//       title: "Development and Testing",
//       img: require('../image/flow/minidreams_development.svg').default,
//       img1: require('../image/flow/minidreams_testing.svg').default,
//       desc: '"Our experienced developers bring your app to life using cutting-edge technologies, ensuring functionality, performance, and scalability. Rigorous testing is conducted at each stage to maintain quality standards."',
//     },
//     {
//       id: 4,
//       title: "Deployment and Maintenance",
//       img: require('../image/flow/minidreams_deployment.svg').default,
//       img1: require('../image/flow/minidreams_maintanance.svg').default,
//       desc: '"We assist you in launching your app on the desired platforms and provide ongoing support and maintenance to keep it up-to-date and optimized."',
//     },
//   ];
// }
