// theme.js
const theme = {
  colors: {
    primary: "#EC1640",
    primaryBlue: "#4E3FC7",
    primaryGreen: "#C7F860",
    primaryOrange: "FC4F00",
    secondary: "#FF3B30",
    textPrimary: "#0d0d0d",
    textSecondary: "#4F4F4E",
    textSecondaryDark: "#DFE9E4",
    white: "#FBFAFF",
    chipBg: "#2C3035",
    greenChipBg: "#136953",
    textSecondaryWhite: "#A6A6A6",
    primarygradient: "linear-gradient(45deg, #fc4985, #893af8)",
    primaryPurple: "#893af8",
    primaryPink: "#fc4985",
    darkbg: "#2e2e2e",
    dullwhite: "#f0ebeb",
  },
  fontSizes: {
    h1: "2.813rem", // Equivalent to 73px with 1rem = 16px and 73 / 16 = 4.5625
    h2: "2.25rem", // You can change this value as needed
    h3: "1.905rem", // You can change this value as needed
    p: "0.75rem", // Equivalent to 18px with 1rem = 16px and 18 / 16 = 1.125
  },
  // Add more theme properties as needed
};

export default theme;
