// GlobalStyle.js
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Trap-Light';
    src: url('/fonts/Trap-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Trap-Regular';
    src: url('/fonts/Trap-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }

 @font-face {
    font-family: 'Trap-Medium';
    src: url('/fonts/Trap-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }


 @font-face {
    font-family: 'Trap-SemiBold';
    src: url('/fonts/Trap-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}
  @font-face {
    font-family: 'Trap-Bold';
    src: url('/fonts/Trap-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }

   @font-face {
    font-family: 'Trap-ExtraBold';
    src: url('/fonts/Trap-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Trap-Black';
    src: url('/fonts/Trap-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
  }

  /* Include other font weights and styles as needed */

  body {
    font-family: 'Trap-Regular', sans-serif; /* Default to regular style */
  }
`;

export default GlobalStyle;
