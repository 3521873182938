import React, { useState, useEffect, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./pages/header/Header";
import Footer from "./pages/footer/Footer";
import GlobalStyle from "../src/pages/theme/GlobalStyle";
import PageLoader from "./pages/UiComponents/PageLoader.js";
// import RedirectToExternal from "./RedirectToExternal.js";
import SubHeader from "./pages/header/SubHeader.js";

// Lazy-loaded components
const Home = React.lazy(() => import("./pages/mainpages/Home.js"));
const About = React.lazy(() => import("./pages/mainpages/About.js"));
const Portfolio = React.lazy(() => import("./pages/mainpages/Portfolio.js"));
const Contact = React.lazy(() => import("./pages/mainpages/Contact.js"));
const MobileApp = React.lazy(() => import("./pages/servicespages/MobileApp"));
const Website = React.lazy(() => import("./pages/servicespages/Website"));
const Service = React.lazy(() => import("./pages/mainpages/Service.js"));

const GraphicsDesign = React.lazy(() =>
  import("./pages/servicespages/GraphicsDesign")
);
const DigitalMarketing = React.lazy(() =>
  import("./pages/servicespages/DigitalMarketing")
);
const NotFound = React.lazy(() => import("./pages/mainpages/404"));
const Edit = React.lazy(() => import("./pages/mainpages/Edit.js"));
const AllBlogs = React.lazy(() => import("./pages/mainpages/AllBlogs.js"));
const FullBlogsPage = React.lazy(() =>
  import("./pages/mainpages/FullBlogsPage.js")
);

function App() {
  const helmetContext = {};
  const [selectedService, setSelectedService] = useState(null);
  const [blogData, setBlogData] = useState(null);

  function handleServiceSelection(service) {
    setSelectedService(service);
  }

  // Disable the default browser RightClick behavior to avoid image download
  useEffect(() => {
    const body = document.querySelectorAll("body");
    body.forEach((body) => {
      body.addEventListener("contextmenu", disableRightClick);
    });

    return () => {
      body.forEach((body) => {
        body.removeEventListener("contextmenu", disableRightClick);
      });
    };
  }, []);

  const disableRightClick = (e) => {
    e.preventDefault();
  };

  return (
    <HelmetProvider>
      <Suspense fallback={<PageLoader />}>
        <GlobalStyle />
        <BrowserRouter>
          <div>
            <Header />
            <SubHeader/>
            <main>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Home handleServiceSelection={handleServiceSelection} />
                  }
                />
                {/* <Route
                  path="/sitemap"
                  element={
                    <RedirectToExternal url="https://itboomi-strapi-blog-7k9mk.ondigitalocean.app/api/sitemap/index.xml" />
                  }
                /> */}
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/services" element={<Service />} />
                {/* <Route path="/blogs" element={<Blogs />} /> */}
                <Route
                  path="/app-development-agency-coimbatore"
                  element={<MobileApp />}
                />
                <Route
                  path="/web-development-agency-coimbatore"
                  element={<Website />}
                />
                <Route
                  path="/graphics-designing-agency-coimbatore"
                  element={<GraphicsDesign />}
                />
                <Route
                  path="/digital-marketing-agency-coimbatore"
                  element={<DigitalMarketing />}
                />
                <Route path="*" element={<NotFound />} />
                <Route path="/EditPage" element={<Edit />} />
                <Route
                  path="/blogs"
                  element={<AllBlogs setBlogData={setBlogData} />}
                />
                <Route
                  path="/:title"
                  element={<FullBlogsPage blogData={blogData} />}
                />
                <Route path="/sitemap" />
              </Routes>
            </main>
            <Footer />
          </div>
        </BrowserRouter>
      </Suspense>
    </HelmetProvider>
  );
}

export default App;
